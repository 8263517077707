<template>
  <template v-if="scanAgain === false">
    <div
      class="mobile-page-on-desktop"
      :class="{ isLoading: isLoading, 'has-locker-ticket': !isLoading && lockerTransactionStart && transaction.lines.length }"
    >
      <div
        class="locker-selection"
        :class="locker_mode"
      >
        <div class="step-counter radius-anchor last-step">
          <div
            class="radius-anchor step-counter-inner"
            :style="'width: ' + (step / total_steps) * 100 + '%;'"
          />
        </div>  
        <div class="overflow-stretcher">
          <locker-event-mode
            v-if="locker_mode == 'event'"
            ref="event"
            :location_slug="location_slug"
            :event_slug="location.lockers.production_slug"
            @next="nextStep()"
            @previous="previousStep()"
            @lockerSelectionPage="setLockerSelectionPageState($event)"
          />
          <locker-coinlock-mode
            v-else-if="locker_mode == 'coinlock'"
            ref="coinlock"
            :qrcode="$route.query.qrcode"
            :location_slug="location_slug"
            @next="nextStep()"
            @previous="previousStep()"
            @lockerNotFound="setLockerNotFound('notfound')"
          />
        </div>
      </div>

      <div
        class="prev-next-locker-area"
        :class="{ 'has-ticket': !isLoading && lockerTransactionStart && transaction.lines.length, 'is-locker-page': isLockerPage }"
      >
        <button
          v-if="allowedPreviousStep"
          class="btn btn-empty prev-step"
          @click="previousStep()"
        >
          {{ $t('lockers.previous_step') }}
        </button>
        <!-- {{step}} / {{total_steps }} -->
        <button
          class="btn btn-primary next-step"
          @click="nextStep()"
        >
          {{ $t('lockers.next_step') }}
        </button>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="locker-selection">
      <div class="centered-titles instruction-page">
        <div class="content-container checkout-box radius-anchor">
          <h2>Scan qr code again</h2>

          <LockerInstructionsCoinlock_6 />

          <div class="text-center mt-10">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
// todo: check if lockers available (medium/large)
// todo: rewrite location_id => elockers_location_id
import axios from 'axios';

import * as _location from '../lib/location';
import * as _state from '../lib/state';
import * as _transaction from '../lib/transaction';
import * as _applicationState from '../lib/application-state';

// mixins
import ItemHelper from '../mixins/item.helper.js';
import LockerEventMode from '../components/LockerEventMode';
import LockerCoinlockMode from '../components/LockerCoinlockMode';
import LockerInstructionsCoinlock_6 from '../components/locker-images/coinlock/LockerInstructionsCoinlock_6';

export default {
    name: 'Lockers',
    components: {
        LockerEventMode,
        LockerCoinlockMode,
        LockerInstructionsCoinlock_6,
    },
    mixins: [ItemHelper],
    props: ['location_slug'],
    data() {
        return {
            locker_mode: '',
            loading: true,
            source: 'default',
            allowPreviousStep: true,
            step: 0,
            firstLockerStep: {
                step: 1,
                isSet: false,
            },
            hooliMemberId: null,
            hooliMemberEmail: null,
            total_steps: 1,
            isLockerPage: false,
            hasTicket: false,
            direction: 'pages-forward',
            ketReached: false,
            scanAgain: false,
        };
    },
    computed: {
        allowedPreviousStep() {
            // if (this.locker_mode == 'event') {
            //     return this.allowPreviousStep && this.step !== 0 && this.step != this.firstLockerStep.step;
            // }

            if (!(this.step > 0)) {
                return false;
            }

            return true;
        },

        location() {
            return _state.get('location/getLocation');
        },

        transaction() {
            return _state.get('transaction/getTransaction');
        },

        isLoading() {
            return _applicationState.isLoading();
        },

        lockerTransactionStart() {
            return _state.get('lockers/getStartTransactionTimestamp');
        },
    },
    async mounted() {
        // always clear basket
        _transaction.clear();

        if (this.$route.query.coinlock) {
            // _transaction.clear();
            this.scanAgain = true;
        }

        if (this.$route.query.hooliMemberEmail) {
            this.hooliMemberEmail = this.$route.query.hooliMemberEmail;
        }

        if (this.$route.query.hooliMemberId) {
            this.hooliMemberId = this.$route.query.hooliMemberId;
        }

        if (this.$route.query.hooliEventId) {
            this.hooliEventId = this.$route.query.hooliEventId;
        }

        if (this.hooliMemberId || this.hooliMemberEmail || this.hooliEventId) {
            _transaction.setHooliMember({
                hooliMemberId: this.hooliMemberId,
                hooliMemberEmail: this.hooliMemberEmail,
                hooliEventId: this.hooliEventId,
            });
        }

        // // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        // let vh = window.innerHeight * 0.01;
        // // Then we set the value in the --vh custom property to the root of the document
        // document.documentElement.style.setProperty('--vh', `${vh}px`);
        _transaction.setMethod('elockers');

        axios.defaults.headers.common['Location'] = this.location_slug;

        if (!this.location.slug) {
            await _location.init(this.location_slug);
        }

        if (this.$route.query.qrcode) {
            this.locker_mode = 'coinlock';

            this.$router.replace({
                query: {
                    coinlock: true,
                },
            });
        } else {
            this.locker_mode = 'event';
        }

        this.loading = false;
    },

    methods: {
        setTotalSteps() {
            this.total_steps = this.$refs[this.locker_mode].getTotalSteps();
        },

        setLockerSelectionPageState(evt) {
            this.isLockerPage = evt;
        },

        setLockerNotFound(error) {
            this.scanAgain = true;
            this.errorMessage = this.$t(`lockers.notifications.${error}`);
        },

        previousStep() {
            if (this.step > 0) {
                this.$refs[this.locker_mode].previous();
                this.step--;
            }
        },

        nextStep() {
            if (this.firstLockerStep.isSet === false) {
                this.firstLockerStep.isSet = true;
                this.setTotalSteps();
            }

            let nextStep = this.$refs[this.locker_mode].next();

            console.log('nextStep', nextStep)

            if (nextStep === true) {
                this.$router.push({
                    name: 'checkout.transaction',
                    params: { location_slug: this.location.slug, lockerTransaction: true },
                    query: this.$route.query,
                });
                return;
            }

            if (nextStep !== false) {
                if (this.step < this.total_steps) {
                    this.step++;
                }
            }
        },
    },
};
</script>

<style>
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    box-shadow: 9999px 0 0 -5px #fff;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #fff;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #fff;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #fff;
    }
    30% {
        box-shadow: 9984px 0 0 2px #fff;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #fff;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #fff;
    }
    30% {
        box-shadow: 9999px 0 0 2px #fff;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #fff;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #fff;
    }
    30% {
        box-shadow: 10014px 0 0 2px #fff;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #fff;
    }
}
</style>
