<template>
    <div v-if="loading" class="locker-loading loading-full-screen">
        <div class="snippet" data-title=".dot-pulse">
            <div class="stage">
                <div class="dot-pulse"></div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="overflow-stretcher">
            <transition-group :name="direction" tag="div" class="locker-pages" id="locker-flow-pages">
                <section v-if="step == 0">
                    <h2>Huur deze locker</h2>
                    <p>
                        Je staat op het punt om onderstaande locker te reserveren. Na de check-out krijg je je pincode die je gedurende de dag kunt
                        gebruiken om in je locker te kunnen.
                    </p>

                    <div class="locker-products mb-8" v-if="data.locker">
                        <div class="option-list">
                            <div class="locker-item bought radius-anchor active">
                                <div class="product-content">
                                    <div class="name">{{ data.locker.attributes.elockers_type }}</div>
                                    <div class="price locker-color">{{ $n(this.data.locker.attributes.price, currency) }}</div>
                                </div>
                                <div class="product-content">
                                    <div class="name">{{ data.locker.attributes.elockers_locker_number }}</div>
                                    <!-- <div class="price">{{ ticket.attributes.pincode }}</div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- {{data}} -->

                    <!-- <div class="content-container checkout-box radius-anchor">
                        <div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
                            <h4>Locker</h4>
                            <span class="flex-grow">{{ this.data.locker.attributes.elockers_locker_number }}</span>
                        </div>

                        <div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
                            <h4>Event</h4>
                            <span class="flex-grow">{{ this.data.event.name }}</span>
                        </div>

                        <div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
                            <h4>Location</h4>
                            <span class="flex-grow">{{ this.data.location.name }}</span>
                        </div>

                        <div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
                            <h4>Area</h4>
                            <span class="flex-grow">{{ this.data.area.name }}</span>
                        </div>

                        <div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
                            <h4>Price</h4>
                            <span class="flex-grow">{{ $n(this.data.locker.attributes.price, currency) }}</span>
                        </div>
                    </div> -->
                </section>

                <!-- <section v-if="step == 2" class="centered-titles instruction-page">
                    <h2><span class="highlighted">NOTIFICATION:</span> Single use!</h2>

                    <p>
                        You can only use the pin code to close and open the locker once. If you want to use it again, you need to buy a new pin code
                        by scanning the QR code again.
                    </p>

                    <LockerInstructionsCoinlock_1 />
                </section>

                <section v-else-if="step == 3" class="centered-titles instruction-page">
                    <h2>Store your belongings</h2>

                    <p>Place your beloningins inside the locker.</p>
                </section>

                <section v-else-if="step == 4" class="centered-titles instruction-page">
                    <h2>Close the locker</h2>
                    <p>Enter the <span class="highlighted">pin code</span> and turn the knob to the <span class="highlighted">left</span>.</p>
                    <LockerInstructions_4 />
                </section>

                <section v-else-if="step == 5" class="centered-titles instruction-page">
                    <h2>Check if the locker is securely closed</h2>

                    <p>Make sure the locker is securely closed.</p>
                </section>

                <section v-if="step == 2" class="centered-titles instruction-page">
                    <h2><span class="highlighted">NOTIFICATION:</span> Single use!</h2>

                    <p>
                        You can only use the pin code to close and open the locker once. If you want to use it again, you need to buy a new pin code
                        by scanning the QR code again.
                    </p>

                    <LockerInstructionsCoinlock_1 />
                </section> -->

                <section v-else-if="step == 7" class="centered-titles instruction-page">
                    <h2>Scan qr code again</h2>

                    <LockerInstructionsCoinlock_6 />
                </section>
            </transition-group>
        </div>
    </div>
</template>

<script>
// helpers
import ItemHelper from '../mixins/item.helper.js';

// libs
import * as _api from '../lib/api';
import * as _applicationState from '../lib/application-state';
import * as _state from '../lib/state';

//locker illustrations
import LockerInstructionsCoinlock_6 from './locker-images/coinlock/LockerInstructionsCoinlock_6';
// import LockerInstructionsCoinlock_1 from './locker-images/coinlock/LockerInstructionsCoinlock_1';
// import LockerInstructionsCoinlock_3 from './locker-images/coinlock/LockerInstructionsCoinlock_3';
// import LockerInstructions_4 from './locker-images/event/LockerInstructions_4';

export default {
    name: 'LockerCoinlockMode',
    mixins: [ItemHelper],
    props: ['qrcode', 'location_slug'],
    components: {
        LockerInstructionsCoinlock_6,
        // LockerInstructionsCoinlock_1,
        // LockerInstructionsCoinlock_3,
        // LockerInstructions_4,
    },
    data() {
        return {
            step: 0,
            total_steps: 7,
            allowPreviousStep: true,
            direction: 'pages-forward',
            data: null,
            loading: true,
        };
    },
    async mounted() {
        this.data = await _api.get('elockers/coinlock/{locker_uuid}', this.qrcode);

        if (this.data.result === 'failed') {
            this.$emit('lockerNotFound');
            this.$router.push({ name: 'lockers', params: { location_slug: this.location_slug } });
        }

        this.loading = false;
    },
    methods: {
        getTotalSteps() {
            return this.total_steps;
        },

        previous() {
            if (this.step > 1) {
                this.step--;
                this.direction = 'pages-back';

                document.querySelector('.overflow-stretcher').scrollTop = 0;
            }
        },

        next() {
            if (this.step < this.total_steps) {
                this.step++;
                this.direction = 'pages-forward';
            }

            if (this.step == 1) {
                _applicationState.setLockerFlowStep(this.step + 1);
                _applicationState.setLockerFlowTotalSteps(this.total_steps);

                this.addItemByScannedBarcode(this.data.locker);
                return true;
            }

            document.querySelector('.overflow-stretcher').scrollTop = 0;
        },
    },
    computed: {
        config() {
            return _state.get('config/getConfig');
        },

        currency() {
            return this.config.settings.currency;
        },
    },
};
</script>
