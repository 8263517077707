<template>
    <div class="svg-wrapper locker-instructions-coinlock-6">
        <!-- Generator: Adobe Illustrator 27.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg
            version="1.1"
            id="Laag_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 151.66 67.46"
            style="enable-background: new 0 0 151.66 67.46"
            xml:space="preserve"
        >
            <g>
                <g>
                    <defs>
                        <path
                            id="SVGID_1_"
                            d="M141.58,60.44H11.39C9.52,60.44,8,58.92,8,57.04V10.42c0-1.87,1.52-3.39,3.39-3.39h130.18
				c1.87,0,3.39,1.52,3.39,3.39v46.63C144.97,58.92,143.45,60.44,141.58,60.44z"
                        />
                    </defs>
                    <clipPath id="SVGID_00000083085510017967001090000003777475775860332170_">
                        <use xlink:href="#SVGID_1_" style="overflow: visible" />
                    </clipPath>
                    <g style="clip-path: url(#SVGID_00000083085510017967001090000003777475775860332170_)">
                        <rect x="4.48" y="3.94" class="st2" width="147.3" height="61.88" />
                        <path
                            class="st4"
                            d="M155.22,63.5c0,1.28-1.39,2.32-3.1,2.32H85.21H7.15c-1.71,0-3.1-1.04-3.1-2.32v-6.26V5.03
				c0-1.28,1.39-2.32,3.1-2.32h66.91h78.06c1.71,0,3.1,1.04,3.1,2.32v6.26V63.5z"
                        />
                        <g>
                            <path
                                class="st5"
                                d="M48.91,18.5C48.91,18.5,48.91,18.5,48.91,18.5c-0.26-0.04-0.44-0.27-0.4-0.53l0.42-3.07
					c0.04-0.26,0.27-0.44,0.53-0.4c0,0,0,0,0,0c0.26,0.04,0.44,0.27,0.4,0.53l-0.42,3.07C49.41,18.36,49.17,18.54,48.91,18.5z"
                            />
                            <path
                                class="st5"
                                d="M41.21,20.63l3.07,0.42c0,0,0,0,0,0c0.26,0.04,0.44,0.27,0.4,0.53c-0.04,0.26-0.27,0.44-0.53,0.4
					l-3.07-0.42c0,0,0,0,0,0c-0.26-0.04-0.44-0.27-0.4-0.53C40.72,20.77,40.96,20.59,41.21,20.63z"
                            />
                            <path
                                class="st5"
                                d="M45.26,18.79c-0.12-0.02-0.23-0.08-0.31-0.18l-1.88-2.47c-0.16-0.21-0.12-0.5,0.09-0.66
					c0.21-0.16,0.5-0.12,0.66,0.09l1.88,2.47c0.16,0.21,0.12,0.5-0.09,0.66C45.5,18.78,45.38,18.81,45.26,18.79z"
                            />
                            <path
                                class="st6"
                                d="M52.77,25.96v-2.54c0-0.22-0.18-0.39-0.39-0.39h-2.54c-0.22,0-0.39,0.18-0.39,0.39v2.54
					c0,0.22,0.18,0.39,0.39,0.39h2.54C52.6,26.35,52.77,26.18,52.77,25.96z M51.99,25.57h-1.76v-1.76h1.76V25.57z"
                            />
                            <path
                                class="st6"
                                d="M56.14,25.08h-1.17c-0.22,0-0.39-0.18-0.39-0.39c0-0.22,0.18-0.39,0.39-0.39h0.78v-0.88
					c0-0.22,0.18-0.39,0.39-0.39c0.22,0,0.39,0.18,0.39,0.39v1.27C56.53,24.91,56.36,25.08,56.14,25.08z"
                            />
                            <path
                                class="st6"
                                d="M56.14,30.09c-0.22,0-0.39-0.18-0.39-0.39v-0.76h-0.78c-0.22,0-0.39-0.18-0.39-0.39v-0.84h-0.82
					c-0.22,0-0.39-0.18-0.39-0.39v-1.36c0-0.22,0.18-0.39,0.39-0.39h1.21c0.22,0,0.39,0.18,0.39,0.39c0,0.22-0.18,0.39-0.39,0.39
					h-0.82v0.58h0.82c0.22,0,0.39,0.18,0.39,0.39v0.84h0.78c0.22,0,0.39,0.18,0.39,0.39v1.15C56.53,29.92,56.36,30.09,56.14,30.09z"
                            />
                            <path
                                class="st6"
                                d="M54.76,23.42c0,0.22-0.18,0.39-0.39,0.39h-0.21v0.88c0,0.22-0.18,0.39-0.39,0.39s-0.39-0.18-0.39-0.39
					v-1.27c0-0.22,0.18-0.39,0.39-0.39h0.6C54.58,23.03,54.76,23.21,54.76,23.42z"
                            />
                            <path
                                class="st6"
                                d="M56.14,31.53h-1.17c-0.22,0-0.39-0.18-0.39-0.39v-1.15c0-0.22,0.18-0.39,0.39-0.39
					c0.22,0,0.39,0.18,0.39,0.39v0.75h0.78c0.22,0,0.39,0.18,0.39,0.39C56.53,31.35,56.36,31.53,56.14,31.53z"
                            />
                            <path
                                class="st6"
                                d="M54.95,32.02h1.19c0.22,0,0.39,0.18,0.39,0.39c0,0.22-0.18,0.39-0.39,0.39h-0.8v0.49h0.8
					c0.22,0,0.39,0.18,0.39,0.39c0,0.22-0.18,0.39-0.39,0.39h-1.19c-0.22,0-0.39-0.18-0.39-0.39v-1.27
					C54.56,32.19,54.74,32.02,54.95,32.02z"
                            />
                            <path
                                class="st6"
                                d="M51.22,27.83c0-0.22,0.18-0.39,0.39-0.39h0.77c0.22,0,0.39,0.18,0.39,0.39c0,0.22-0.18,0.39-0.39,0.39
					h-0.77C51.4,28.22,51.22,28.05,51.22,27.83z"
                            />
                            <path
                                class="st6"
                                d="M56.14,25.72c0.22,0,0.39,0.18,0.39,0.39v0.77c0,0.22-0.18,0.39-0.39,0.39c-0.22,0-0.39-0.18-0.39-0.39
					v-0.77C55.75,25.9,55.93,25.72,56.14,25.72z"
                            />
                            <path
                                class="st6"
                                d="M49.85,34.07c-0.22,0-0.39-0.18-0.39-0.39v-0.95c0-0.22,0.18-0.39,0.39-0.39h0.88v-1.19
					c0-0.22,0.18-0.39,0.39-0.39c0.22,0,0.39,0.18,0.39,0.39v1.58c0,0.22-0.18,0.39-0.39,0.39h-0.88v0.56
					C50.24,33.89,50.06,34.07,49.85,34.07z"
                            />
                            <path
                                class="st6"
                                d="M52.38,32.8c-0.22,0-0.39-0.18-0.39-0.39c0-0.22,0.18-0.39,0.39-0.39h0.69c0.22,0,0.39,0.18,0.39,0.39v0.88
					h0.3c0.22,0,0.39,0.18,0.39,0.39c0,0.22-0.18,0.39-0.39,0.39h-1.38c-0.22,0-0.39-0.18-0.39-0.39c0-0.22,0.18-0.39,0.39-0.39h0.3
					V32.8H52.38z"
                            />
                            <path
                                class="st6"
                                d="M52.38,29.02c0.22,0,0.39,0.18,0.39,0.39v1.34h0.6v-2.2c0-0.22,0.18-0.39,0.39-0.39s0.39,0.18,0.39,0.39
					v2.59c0,0.22-0.18,0.39-0.39,0.39h-1.38c-0.22,0-0.39-0.18-0.39-0.39v-1.73C51.99,29.2,52.17,29.02,52.38,29.02z"
                            />
                            <path
                                class="st6"
                                d="M49.46,29.13c0-0.22,0.18-0.39,0.39-0.39c0.22,0,0.39,0.18,0.39,0.39v0.18h0.88c0.22,0,0.39,0.18,0.39,0.39
					c0,0.22-0.18,0.39-0.39,0.39h-0.88v1.04c0,0.22-0.18,0.39-0.39,0.39c-0.22,0-0.39-0.18-0.39-0.39V29.13z"
                            />
                            <path
                                class="st6"
                                d="M49.46,27.26c0-0.22,0.18-0.39,0.39-0.39h0.63c0.22,0,0.39,0.18,0.39,0.39c0,0.22-0.18,0.39-0.39,0.39
					h-0.24v0.31c0,0.22-0.18,0.39-0.39,0.39c-0.22,0-0.39-0.18-0.39-0.39V27.26z"
                            />
                            <path
                                class="st7"
                                d="M47.8,20.99h3.34c0.24,0,0.43,0.19,0.43,0.43c0,0.24-0.19,0.43-0.43,0.43h-2.91v2.91
					c0,0.24-0.19,0.43-0.43,0.43c-0.24,0-0.43-0.19-0.43-0.43v-3.34C47.37,21.18,47.56,20.99,47.8,20.99z"
                            />
                            <path
                                class="st7"
                                d="M47.83,31.97c0.24,0,0.43,0.19,0.43,0.43v2.91h2.91c0.24,0,0.43,0.19,0.43,0.43c0,0.24-0.19,0.43-0.43,0.43
					h-3.34c-0.24,0-0.43-0.19-0.43-0.43V32.4C47.4,32.16,47.59,31.97,47.83,31.97z"
                            />
                        </g>
                        <g>
                            <g>
                                <path
                                    class="st8"
                                    d="M111.23,60.7c0,0-4.88-3.84-4.78-12.88c0,0,1.04-13.26-0.62-15.61c-1.66-2.35-4.5-7.27-5.12-9.83
						c-0.62-2.56-4.5-2.77-6.16-6.92c-1.66-4.15-4.91-1.11-4.64,2.08c0.28,3.18,2.01,6.09,3.74,8.72c1.73,2.63,1.28,2.98,0.45,5.36
						c-0.83,2.39-2.75,4.62-3.37,10.75c-0.41,4.08,0.93,7.53,1.71,9.09"
                                />
                                <path class="st8" d="M90.77,14.3V3.67c0-1.25-1.01-2.26-2.26-2.26H67.7c-1.25,0-2.26,1.01-2.26,2.26v21.62" />
                                <line class="st8" x1="90.77" y1="41.63" x2="90.77" y2="21.24" />
                                <path class="st8" d="M65.44,47.28v3.89c0,1.25,1.01,2.26,2.26,2.26h20.81c1.25,0,2.26-1.01,2.26-2.26v-4.99" />
                                <path class="st8" d="M91.29,67.45c-2.18-2.91-2.49-4.46-5.61-5.92c-3.11-1.45-7.27-3.22-9.97-8.1" />
                                <path class="st8" d="M65.44,15.67c0,0-3.91-3.32-5.97-0.93c-1.87,2.17,1.09,5.47,5.97,8.86" />
                                <path
                                    class="st8"
                                    d="M63.26,39c1.04,0.61,3.74,2.02,4.72,3.89s-0.26,5.97-4.93,3.37c-4.67-2.6-3.92-4.41-3.41-5.81
						C60.14,39.05,62.03,38.28,63.26,39z"
                                />
                                <path
                                    class="st8"
                                    d="M63.78,31.68c2.05,1.28,3.68,3.16,3.11,5.39c-0.57,2.24-2.13,2.65-3.63,1.93c-1.51-0.73-4.05-2.96-4-4.93
						C59.31,32.09,60.77,29.81,63.78,31.68z"
                                />
                                <path
                                    class="st8"
                                    d="M63.78,24.12c2.09,1.43,2.72,2.32,2.57,4.5c-0.16,2.18-1.01,3.53-3.21,2.71
						c-2.04-0.75-2.78-1.73-3.09-2.66c-0.31-0.93-0.52-3.17,0.88-4.21C62.32,23.42,63.46,23.9,63.78,24.12z"
                                />
                                <g>
                                    <line class="st8" x1="82.19" y1="4.89" x2="76.44" y2="4.89" />
                                    <line class="st8" x1="74.02" y1="4.89" x2="74.02" y2="4.89" />
                                </g>
                                <line class="st8" x1="82.19" y1="49.95" x2="74.02" y2="49.95" />
                            </g>
                        </g>
                        <path
                            class="st6"
                            d="M73.07,32.32c0,0.22,0.18,0.39,0.39,0.39H76c0.22,0,0.39-0.18,0.39-0.39v-2.54c0-0.22-0.18-0.39-0.39-0.39
				h-2.54c-0.22,0-0.39,0.18-0.39,0.39V32.32z M73.85,30.17h1.76v1.76h-1.76V30.17z"
                        />
                        <path
                            class="st6"
                            d="M76,21.67h-2.54c-0.22,0-0.39,0.18-0.39,0.39v2.54c0,0.22,0.18,0.39,0.39,0.39H76
				c0.22,0,0.39-0.18,0.39-0.39v-2.54C76.39,21.85,76.21,21.67,76,21.67z M75.61,24.21h-1.76v-1.76h1.76V24.21z"
                        />
                        <path
                            class="st6"
                            d="M80.78,22.07v2.54c0,0.22,0.18,0.39,0.39,0.39h2.54c0.22,0,0.39-0.18,0.39-0.39v-2.54
				c0-0.22-0.18-0.39-0.39-0.39h-2.54C80.96,21.67,80.78,21.85,80.78,22.07z M81.57,22.46h1.76v1.76h-1.76V22.46z"
                        />
                        <path
                            class="st6"
                            d="M77.41,21.67c-0.22,0-0.39,0.18-0.39,0.39v1.27c0,0.22,0.18,0.39,0.39,0.39h1.17c0.22,0,0.39-0.18,0.39-0.39
				s-0.18-0.39-0.39-0.39h-0.78v-0.88C77.81,21.85,77.63,21.67,77.41,21.67z"
                        />
                        <path
                            class="st6"
                            d="M73.46,26.35h0.88c0.22,0,0.39-0.18,0.39-0.39c0-0.22-0.18-0.39-0.39-0.39h-0.88
				c-0.22,0-0.39,0.18-0.39,0.39C73.07,26.18,73.25,26.35,73.46,26.35z"
                        />
                        <path
                            class="st6"
                            d="M75.61,25.96v1.23c0,0.22,0.18,0.39,0.39,0.39c0.22,0,0.39-0.18,0.39-0.39v-0.84h1.03
				c0.22,0,0.39-0.18,0.39-0.39V24.6c0-0.22-0.18-0.39-0.39-0.39c-0.22,0-0.39,0.18-0.39,0.39v0.97H76
				C75.78,25.57,75.61,25.75,75.61,25.96z"
                        />
                        <path
                            class="st6"
                            d="M80.19,25.96V24.6c0-0.22-0.18-0.39-0.39-0.39h-1.21c-0.22,0-0.39,0.18-0.39,0.39
				c0,0.22,0.18,0.39,0.39,0.39h0.82v0.58h-0.82c-0.22,0-0.39,0.18-0.39,0.39v0.84h-0.78c-0.22,0-0.39,0.18-0.39,0.39v1.15
				c0,0.22,0.18,0.39,0.39,0.39c0.22,0,0.39-0.18,0.39-0.39v-0.76h0.78c0.22,0,0.39-0.18,0.39-0.39v-0.84h0.82
				C80.01,26.35,80.19,26.18,80.19,25.96z"
                        />
                        <path
                            class="st6"
                            d="M79.8,23.73c0.22,0,0.39-0.18,0.39-0.39v-1.27c0-0.22-0.18-0.39-0.39-0.39h-0.6c-0.22,0-0.39,0.18-0.39,0.39
				s0.18,0.39,0.39,0.39h0.21v0.88C79.4,23.55,79.58,23.73,79.8,23.73z"
                        />
                        <path
                            class="st6"
                            d="M73.07,28.34c0,0.22,0.18,0.39,0.39,0.39c0.22,0,0.39-0.18,0.39-0.39v-1.15c0-0.22-0.18-0.39-0.39-0.39
				c-0.22,0-0.39,0.18-0.39,0.39V28.34z"
                        />
                        <path
                            class="st6"
                            d="M76,28.24h-0.63v-0.77c0-0.22-0.18-0.39-0.39-0.39c-0.22,0-0.39,0.18-0.39,0.39v1.16
				c0,0.22,0.18,0.39,0.39,0.39H76c0.22,0,0.39-0.18,0.39-0.39C76.39,28.42,76.21,28.24,76,28.24z"
                        />
                        <path
                            class="st6"
                            d="M77.41,30.17h1.17c0.22,0,0.39-0.18,0.39-0.39v-1.15c0-0.22-0.18-0.39-0.39-0.39
				c-0.22,0-0.39,0.18-0.39,0.39v0.75h-0.78c-0.22,0-0.39,0.18-0.39,0.39C77.02,29.99,77.2,30.17,77.41,30.17z"
                        />
                        <path
                            class="st6"
                            d="M77.41,31.92c-0.22,0-0.39,0.18-0.39,0.39c0,0.22,0.18,0.39,0.39,0.39h1.19c0.22,0,0.39-0.18,0.39-0.39
				v-1.27c0-0.22-0.18-0.39-0.39-0.39h-1.19c-0.22,0-0.39,0.18-0.39,0.39c0,0.22,0.18,0.39,0.39,0.39h0.8v0.49H77.41z"
                        />
                        <path
                            class="st6"
                            d="M81.17,26.86h0.77c0.22,0,0.39-0.18,0.39-0.39c0-0.22-0.18-0.39-0.39-0.39h-0.77
				c-0.22,0-0.39,0.18-0.39,0.39C80.78,26.69,80.96,26.86,81.17,26.86z"
                        />
                        <path
                            class="st6"
                            d="M82.05,31.36c0,0.22,0.18,0.39,0.39,0.39h0.88v0.56c0,0.22,0.18,0.39,0.39,0.39c0.22,0,0.39-0.18,0.39-0.39
				v-0.95c0-0.22-0.18-0.39-0.39-0.39h-0.88v-1.19c0-0.22-0.18-0.39-0.39-0.39c-0.22,0-0.39,0.18-0.39,0.39V31.36z"
                        />
                        <path
                            class="st6"
                            d="M81.17,31.44c0.22,0,0.39-0.18,0.39-0.39c0-0.22-0.18-0.39-0.39-0.39h-0.69c-0.22,0-0.39,0.18-0.39,0.39
				v0.88h-0.3c-0.22,0-0.39,0.18-0.39,0.39c0,0.22,0.18,0.39,0.39,0.39h1.38c0.22,0,0.39-0.18,0.39-0.39c0-0.22-0.18-0.39-0.39-0.39
				h-0.3v-0.49H81.17z"
                        />
                        <path
                            class="st6"
                            d="M80.78,29.39h-0.6v-2.2c0-0.22-0.18-0.39-0.39-0.39c-0.22,0-0.39,0.18-0.39,0.39v2.59
				c0,0.22,0.18,0.39,0.39,0.39h1.38c0.22,0,0.39-0.18,0.39-0.39v-1.73c0-0.22-0.18-0.39-0.39-0.39c-0.22,0-0.39,0.18-0.39,0.39
				V29.39z"
                        />
                        <path
                            class="st6"
                            d="M82.05,28.34c0,0.22,0.18,0.39,0.39,0.39h0.88v1.04c0,0.22,0.18,0.39,0.39,0.39c0.22,0,0.39-0.18,0.39-0.39
				v-2.01c0-0.22-0.18-0.39-0.39-0.39c-0.22,0-0.39,0.18-0.39,0.39v0.18h-0.88C82.23,27.95,82.05,28.13,82.05,28.34z"
                        />
                        <path
                            class="st6"
                            d="M83.71,26.99c0.22,0,0.39-0.18,0.39-0.39v-0.7c0-0.22-0.18-0.39-0.39-0.39h-0.63
				c-0.22,0-0.39,0.18-0.39,0.39c0,0.22,0.18,0.39,0.39,0.39h0.24v0.31C83.32,26.81,83.5,26.99,83.71,26.99z"
                        />
                        <path
                            class="st7"
                            d="M74.78,19.63h-3.34c-0.24,0-0.43,0.19-0.43,0.43v3.34c0,0.24,0.19,0.43,0.43,0.43
				c0.24,0,0.43-0.19,0.43-0.43v-2.91h2.91c0.24,0,0.43-0.19,0.43-0.43C75.21,19.82,75.02,19.63,74.78,19.63z"
                        />
                        <path
                            class="st7"
                            d="M86.19,20.06c0-0.24-0.19-0.43-0.43-0.43h-3.34c-0.24,0-0.43,0.19-0.43,0.43c0,0.24,0.19,0.43,0.43,0.43
				h2.91v2.91c0,0.24,0.19,0.43,0.43,0.43c0.24,0,0.43-0.19,0.43-0.43V20.06z"
                        />
                        <path
                            class="st7"
                            d="M70.98,34.38c0,0.24,0.19,0.43,0.43,0.43h3.34c0.24,0,0.43-0.19,0.43-0.43s-0.19-0.43-0.43-0.43h-2.91v-2.91
				c0-0.24-0.19-0.43-0.43-0.43c-0.24,0-0.43,0.19-0.43,0.43V34.38z"
                        />
                        <path
                            class="st7"
                            d="M82.39,34.81h3.34c0.24,0,0.43-0.19,0.43-0.43v-3.34c0-0.24-0.19-0.43-0.43-0.43
				c-0.24,0-0.43,0.19-0.43,0.43v2.91h-2.91c-0.24,0-0.43,0.19-0.43,0.43S82.15,34.81,82.39,34.81z"
                        />
                    </g>
                </g>
                <path
                    class="st9"
                    d="M141.93,60.44H9.73c-1.89,0-3.42-1.53-3.42-3.42V10.44c0-1.89,1.53-3.42,3.42-3.42h132.2
		c1.89,0,3.42,1.53,3.42,3.42v46.57C145.35,58.91,143.82,60.44,141.93,60.44z"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LockerInstructionsCoinlock_6',
};
</script>
