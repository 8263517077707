<template>
    <div>
        <div v-if="this.lockerTransactionStart" class="mb-4 w-full btn btn-highlight">
            <div class="text-gray-600 text-sm">
                {{ $t('shoppingcart.timer') }}
                {{ this.formatTimer }}
            </div>
        </div>
    </div>
</template>

<script>
import * as _state from '../lib/state';
import * as _helpers from '../lib/helpers';

export default {
    components: {},
    data() {
        return {
            cancelTransactionTimer: null,
        };
    },
    mounted() {
        this.cancelLockerTransactionTimer();
    },
    methods: {
        cancelLockerTransactionTimer(init = false) {
            this.cancelTransactionTimer = Math.round((5 * 60 * 1000 - (Date.now() - this.lockerTransactionStart)) / 1000);

            if (this.cancelTransactionTimer > 0) {
                this.cancelTransactionTimer--;
                setTimeout(this.cancelLockerTransactionTimer, 1000);
            }
        },
    },
    watch: {
        lockerTransactionStart: function (val) {
            this.cancelLockerTransactionTimer(true);
        },
    },
    computed: {
        formatTimer() {
            return _helpers.formatSeconds(this.cancelTransactionTimer);
        },

        lockerTransactionStart() {
            return _state.get('lockers/getStartTransactionTimestamp');
        },
    },
};
</script>
